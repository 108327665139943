import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useFilter } from 'contexts/FilterContext';
import dayjs from 'dayjs';
import { useSpring, animated } from 'react-spring';

const AnimatedNumber = ({ value }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: value,
    delay: 50,
    config: { mass: 1, tension: 700, friction: 20 }
  });

  return <animated.div>{number.to(n => n.toFixed(0))}</animated.div>;
};

const Cobrancas = ({ faturasPendentes, faturasVencidas, faturasPagas }) => {
  const [cobrancas, setCobrancas] = useState({
    previstas: 0,
    vencidas: 0,
    recebidas: 0,
  });

  const { filtro } = useFilter();

  const calcularCobrancas = () => {
    const obterDataComparacao = (fatura) => {
      const data = filtro.compararPor === 'vencimento' ? dayjs(fatura.due_date) : dayjs(fatura.created_at_iso);
      return data;
    };

    const filtrarFaturasPorPeriodo = (faturas) => {
      const hoje = dayjs();
      const ultimos30Dias = hoje.subtract(30, 'day');
      const ultimos60Dias = hoje.subtract(60, 'day');
      const ultimos90Dias = hoje.subtract(90, 'day');
      const inicioDoAno = hoje.startOf('year');

      return faturas.filter(fatura => {
        const dataComparacao = obterDataComparacao(fatura);
        if (filtro.periodo === 'completo') {
          return true;
        } else if (filtro.periodo === '30dias') {
          return dataComparacao.isAfter(ultimos30Dias);
        } else if (filtro.periodo === '60dias') {
          return dataComparacao.isAfter(ultimos60Dias);
        } else if (filtro.periodo === '90dias') {
          return dataComparacao.isAfter(ultimos90Dias);
        } else if (filtro.periodo === 'esteano') {
          return dataComparacao.isAfter(inicioDoAno);
        }
        return true;
      });
    };

    const faturasVencidasFiltradas = filtrarFaturasPorPeriodo(faturasVencidas);
    const faturasPagasFiltradas = filtrarFaturasPorPeriodo(faturasPagas);
    const faturasPendentesFiltradas = filtrarFaturasPorPeriodo(faturasPendentes);

    setCobrancas({
      previstas: faturasPendentesFiltradas.length,
      vencidas: faturasVencidasFiltradas.length,
      recebidas: faturasPagasFiltradas.length,
    });
  };

  useEffect(() => {
    calcularCobrancas();
  }, [filtro, faturasPendentes, faturasVencidas, faturasPagas]);

  return (
    <div className="col-6 numberLabel-col">
      <h3 className="chartTitle">Cobranças</h3>
      <div className="numberLabel orange">
        <div className="number"><AnimatedNumber value={cobrancas.previstas} /></div>
        <div className="label">Previstas</div>
      </div>
      <div className="numberLabel red">
        <div className="number"><AnimatedNumber value={cobrancas.vencidas} /></div>
        <div className="label">Vencidas</div>
      </div>
      <div className="numberLabel green">
        <div className="number"><AnimatedNumber value={cobrancas.recebidas} /></div>
        <div className="label">Recebidas</div>
      </div>
    </div>
  );
}

export default Cobrancas;
