import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { valorRecebidoGeralOptions } from 'utils/options';

const InPeriodo = ({ title, faturasEmitidasPorMes}) => {
  const inadimplenciaNoPeriodo = {
    labels: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
    datasets: [
      {
        label: 'Período atual',
        backgroundColor: '#EA7B7B',
        borderColor: '#EA7B7B',
        borderWidth: 1,
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        label: 'Período passado',
        backgroundColor: '#FFC0C0',
        borderColor: '#FFC0C0',
        borderWidth: 1,
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  };

  useEffect(() => {
  }, [faturasEmitidasPorMes]);

  return (
    <div className="col-4">
      <h3 className="chartTitle">{title}</h3>
      <Bar data={inadimplenciaNoPeriodo} options={valorRecebidoGeralOptions} />
    </div>
  );
};

export default InPeriodo;
