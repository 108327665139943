import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useFilter } from 'contexts/FilterContext';
import dayjs from 'dayjs';
import { useSpring, animated } from 'react-spring';

const AnimatedNumber = ({ value }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: value,
    delay: 50,
    config: { mass: 1, tension: 700, friction: 20 }
  });

  return <animated.div>{number.to(n => n.toFixed(0))}</animated.div>;
};

const Clientes = ({ faturasPendentes, faturasVencidas, faturasPagas }) => {
  const [clientes, setClientes] = useState({
    emDia: 0,
    inadimplentes: 0,
  });

  const { filtro } = useFilter();

  const calcularClientes = () => {
    const clientesInadimplentes = new Set();
    const clientesEmDia = new Set();

    const obterDataComparacao = (fatura) => {
      const data = filtro.compararPor === 'vencimento' ? dayjs(fatura.due_date) : dayjs(fatura.created_at_iso);
      return data;
    };

    const filtrarFaturasPorPeriodo = (faturas) => {
      const hoje = dayjs();
      const ultimos30Dias = hoje.subtract(30, 'day');
      const ultimos60Dias = hoje.subtract(60, 'day');
      const ultimos90Dias = hoje.subtract(90, 'day');
      const inicioDoAno = hoje.startOf('year');

      return faturas.filter(fatura => {
        const dataComparacao = obterDataComparacao(fatura);
        if (filtro.periodo === 'completo') {
          return true;
        } else if (filtro.periodo === '30dias') {
          return dataComparacao.isAfter(ultimos30Dias);
        } else if (filtro.periodo === '60dias') {
          return dataComparacao.isAfter(ultimos60Dias);
        } else if (filtro.periodo === '90dias') {
          return dataComparacao.isAfter(ultimos90Dias);
        } else if (filtro.periodo === 'esteano') {
          return dataComparacao.isAfter(inicioDoAno);
        }
        return true;
      });
    };

    const faturasVencidasFiltradas = filtrarFaturasPorPeriodo(faturasVencidas);
    const faturasPagasFiltradas = filtrarFaturasPorPeriodo(faturasPagas);
    const faturasPendentesFiltradas = filtrarFaturasPorPeriodo(faturasPendentes);

    faturasVencidasFiltradas.forEach(fatura => {
      clientesInadimplentes.add(fatura.payer_cpf_cnpj);
    });

    faturasPagasFiltradas.concat(faturasPendentesFiltradas).forEach(fatura => {
      if (!clientesInadimplentes.has(fatura.payer_cpf_cnpj)) {
        clientesEmDia.add(fatura.payer_cpf_cnpj);
      }
    });

    setClientes({
      emDia: clientesEmDia.size,
      inadimplentes: clientesInadimplentes.size,
    });
  };

  useEffect(() => {
    calcularClientes();
  }, [filtro, faturasPendentes, faturasVencidas, faturasPagas]);

  return (
    <div className="col-6 numberLabel-col">
      <h3 className="chartTitle">Clientes</h3>
      <div className="numberLabel green" style={{ width: "5rem" }}>
        <div className="number"><AnimatedNumber value={clientes.emDia} /></div>
        <div className="label">Em dia</div>
      </div>
      <div className="numberLabel red">
        <div className="number"><AnimatedNumber value={clientes.inadimplentes} /></div>
        <div className="label">Inadimplentes</div>
      </div>
    </div>
  );
}

export default Clientes;
