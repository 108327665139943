import Field from 'components/Field';
import { Button, DatePicker, Input, InputNumber, Select, Switch } from 'antd';
import { DISPLAY_DATE_FORMAT } from 'utils/formatters';
import DynamicForm from './DynamicForm';
import './style.scss';
import dayjs from 'dayjs';
import { useState } from 'react';
import { invoiceStatus } from 'pages/Faturas';

const FaturaForm = ({
  id,
  data,
  setData,
  setStep,
  handleSubmit,
  submitLabel = 'Escolher cliente',
  clientId,
  justInvoice
}) => {
  const [errors, setErrors] = useState({});
  const [hiddenFields, setHiddenFields] = useState({
    fines: false,
    early_payment_discount: false,
    ignore_due_email: false,
  })

  const hasSomeId = clientId;

  const getTotal = (state) => ((state.total_cents || 0) / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

  const dateChange = (_, dateString) => {
    setErrors({
      ...errors,
      due_date: null
    })
    return setData({
      ...data,
      due_date: dateString
    })
  }

  const selectChange = (value) => {
    setErrors({
      ...errors,
      payable_with: null
    })
    return setData({
      ...data,
      payable_with: value
    })
  }

  const statusChange = (value) => {
    return setData({
      ...data,
      status: value
    })
  }

  const switchChange = () => {
    setHiddenFields({
      ...hiddenFields,
      ignore_due_email: !hiddenFields.ignore_due_email
    })

    return setData({
      ...data,
      ...hiddenFields
    })
  }

  const handleChange = (ev) => {
    const id = ev.target.id;

    id === 'discount_cents' &&
      setHiddenFields({
        ...hiddenFields,
        early_payment_discount: true
      })

    return setData({
      ...data,
      ...hiddenFields,
      [id]: ev.target.value
    })
  }

  const handleFinesChange = (value) => {
    setHiddenFields({
      ...hiddenFields,
      fines: true
    })
    return setData({
      ...data,
      ...hiddenFields,
      late_payment_fine: value
    })
  }

  const handlePerDayChange = (value) => {
    return setData({
      ...data,
      per_day_interest_value: value
    })
  }

  const validate = (action) => {
    let hasErrors;
    if (!data.due_date) {
      hasErrors = true;
      setErrors({
        ...errors,
        due_date: 'Validade é obrigatória.'
      })
    }
    if (!data.payable_with) {
      hasErrors = true;
      setErrors({
        ...errors,
        payable_with: 'Método de pagamento é obrigatório.'
      })
    }
    if (!data.items) {
      hasErrors = true;
      setErrors({
        ...errors,
        items: 'É obrigatório ter pelo menos 1 item preenchido corretamente.'
      })
    } else {
      if (data.items.find((item) => !item.description)) {
        hasErrors = true;
        setErrors({
          ...errors,
          items: 'É obrigatório ter todas as descrições preenchidas.'
        })
      }
      if (data.items.find((item) => !item.price_cents)) {
        hasErrors = true;
        setErrors({
          ...errors,
          items: 'É obrigatório ter todos os preços preenchidos.'
        })
      }
    }
    if (!hasErrors) {
      return action()
    }
    return;
  }

  return (
    <div className="novo-fatura">
      <div className="form">
        <div className="form-section">
          <Field label="Validade" errors={errors.due_date}>
            <DatePicker
              disabled={justInvoice}
              id="due_date"
              value={
                data.due_date ?
                  dayjs(data.due_date, DISPLAY_DATE_FORMAT) :
                  ''
              }
              onChange={dateChange}
              format={{
                format: DISPLAY_DATE_FORMAT,
                type: 'mask',
              }}
              minDate={dayjs()}
              status={errors.due_date}
            />
          </Field>
          <Field label="Ref. Externa">
            <Input
              disabled={justInvoice}
              id="external_reference"
              value={data.external_reference}
              onChange={handleChange}
            />
          </Field>
          <Field label="Método de pagamento" errors={errors.payable_with}>
            <Select
              disabled={justInvoice}
              id="payable_with"
              value={data.payable_with}
              onChange={selectChange}
              options={[
                { value: 'all', label: 'Todos' },
                { value: 'credit_card', label: 'Cartão de crédito' },
                { value: 'bank_slip', label: 'Boleto/PIX' },
                // { value: 'pix', label: 'PIX' },
              ]}
              status={errors.payable_with}
            />
          </Field>
          {id &&
            <Field label="Status">
              <Select
                disabled={justInvoice}
                id="status"
                value={data.status}
                onChange={statusChange}
                options={invoiceStatus}
              />
            </Field>
          }
        </div>
        <div className="form-section">
          <h5 className="form-section-title">Itens da fatura</h5>
          <DynamicForm
            disabled={justInvoice}
            data={data}
            setData={setData}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
        <div className="form-section">
          <h5 className="form-section-title">Desconto, Multa e Juros</h5>
          {/* <Field label="Desconto">
            <Currency
              disabled={justInvoice}
              id="discount_cents"
              value={data.discount_cents}
              onChange={handleChange}
            />
          </Field> */}
          <Field
            label="Multa"
            tooltip="A % da multa adicionada no campo ao lado será incorporado ao valor do boleto no 1º dia após o vencimento a % será acrescentada baseado sobre o valor total do vencimento."
          >
            <InputNumber
              disabled={justInvoice}
              id="late_payment_fine"
              value={data.late_payment_fine}
              onChange={handleFinesChange}
              formatter={(value) => `${value}%`}
              parser={(value) => value?.replace('%', '')}
            />
          </Field>
          <Field
            label="Juros"
            tooltip="A % dos juros adicionada no campo ao lado será incorporado ao valor do boleto no 1º dia após o vencimento a % será acrescentada baseado sobre o valor total do vencimento."
          >
            <InputNumber
              disabled={justInvoice}
              id="per_day_interest_value"
              value={data.per_day_interest_value}
              onChange={handlePerDayChange}
              formatter={(value) => `${value}%`}
              parser={(value) => value?.replace('%', '')}
            />
          </Field>
        </div>
        <div className="form-section">
          <h5 className="form-section-title">Configurações Adicionais</h5>
          <Field label="Número de parcelas">
            <Input
              disabled={justInvoice}
              id="max_installments_value"
              value={data.max_installments_value}
              onChange={handleChange}
            />
          </Field>
          <Field
            label="Prazo máx. após vencimento (em dias)"
            tooltip="Prazo máximo para pagamento do boleto após o vencimento. O prazo máximo para pagamento deve ser entre 1 e 30 dias após a data de vencimento."
          >
            <Input
              disabled={justInvoice}
              id="bank_slip_extra_due"
              value={data.bank_slip_extra_due}
              onChange={handleChange}
            />
          </Field>
          <Field label="Definir senha para fatura">
            <Input
              disabled={justInvoice}
              id="password"
              type="password"
              value={data.password}
              onChange={handleChange}
            />
          </Field>
        </div>
        <div className="form-section switch-section">
          <div className="switch-wrapper">
            <Switch
              disabled={justInvoice}
              id="ignore_due_email"
              value={data.ignore_due_email}
              onChange={switchChange}
            />
            Receber notificações de cobrança  
          </div>
        </div>
      </div>
      <div className="form-footer">
        <div className="form-footer-left">
          <div className="total-display">
            <div className="total-display-label">
              Total da Fatura
            </div>
            <div className="total-display-amount">
              {getTotal(data)}
            </div>
          </div>
        </div>
        <div className="form-footer-right">
          {!justInvoice &&
            <Button
              type="primary"
              onClick={() => hasSomeId ? validate(() => handleSubmit(data)) : validate(() => setStep(2))}
            >
              {submitLabel}
            </Button>
          }
        </div>
      </div>
    </div>
  );
}

export default FaturaForm;
