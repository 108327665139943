import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { valorRecebidoGeralOptions } from 'utils/options';

const ValGeral = ({ title, faturasEmitidasPorMes }) => {
  const [valorRecebidoGeral, setValorRecebidoGeral] = useState({
    labels: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
    datasets: [
      {
        label: 'Período atual',
        backgroundColor: '#034930',
        borderColor: '#034930',
        borderWidth: 1,
        data: Array(12).fill(0),
      },
      {
        label: 'Período passado',
        backgroundColor: '#3BCC99',
        borderColor: '#3BCC99',
        borderWidth: 1,
        data: Array(12).fill(0),
      },
    ],
  });

  const processarFaturasPagas = () => {
    const valoresAtual = Array(12).fill(0);
    const valoresPassado = Array(12).fill(0);

    const somarFaturasPagasPorMes = (faturas) => {
      Object.keys(faturas).forEach(monthYear => {
        const [month, year] = monthYear.split('-').map(Number);
        faturas[monthYear].forEach(fatura => {
          if (year === new Date().getFullYear()) {
            valoresAtual[month - 1] += fatura.total_cents; // Soma o valor total das faturas do mês
          } else if (year === new Date().getFullYear() - 1) {
            valoresPassado[month - 1] += fatura.total_cents;
          }
        });
      });
    };

    // Processar apenas faturas pagas por mês de emissão
    somarFaturasPagasPorMes(faturasEmitidasPorMes.pagas);

    // Atualizar o dataset do gráfico com os valores calculados
    setValorRecebidoGeral(prevState => ({
      ...prevState,
      datasets: [
        {
          ...prevState.datasets[0],
          data: valoresAtual.map(value => value / 100), // Converte os valores para reais
        },
        {
          ...prevState.datasets[1],
          data: valoresPassado.map(value => value / 100), // Converte os valores para reais
        }
      ]
    }));
  };

  useEffect(() => {
    processarFaturasPagas();
  }, [faturasEmitidasPorMes]);

  return (
    <div className="col-4">
      <h3 className="chartTitle">{title}</h3>
      <Bar data={valorRecebidoGeral} options={valorRecebidoGeralOptions} />
    </div>
  );
};

export default ValGeral;