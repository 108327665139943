import { useState } from 'react';
import { useStoreInContext } from 'store/main';
import { Button, Input } from 'antd';
import './style.scss';
import { Link, useNavigate } from 'react-router-dom';
import Field from 'components/Field';
import { RightOutlined } from '@ant-design/icons';

function Login() {
  const navigate = useNavigate();
  const state = useStoreInContext(state => state);
  const { auth: { post } } = state;

  const [data, setData] = useState({
    login: null,
    password: null
  });

  const handleChange = (ev) => setData({
    ...data,
    [ev.target.id]: ev.target.value
  });

  const handleSubmit = () => post(state, data).then(() => navigate("/faturas"));

  const handleKeyDown = (ev) => {
    if (ev.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="Login">
      <h1>Login</h1>
      <div className="form">
        <div className="fields">
          <Field label="Email">
            <Input
              id="login"
              type="text"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder="Seu email"
            />
          </Field>
          <Field label="Senha">
            <Input
              id="password"
              type="password"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder="Sua senha"
            />
          </Field>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={!data.login || !data.password} // Ativa o botão apenas se login e senha estiverem preenchidos
          >
            <RightOutlined />
          </Button>
        </div>
        <Link className="forgotLink" to={"/login/esqueceu-senha"}>Esqueceu suas credenciais?</Link>
      </div>
      {/* <div className="sso"></div> */}
      <div className="cadastro">
        Ainda não possui conta? <Link to={`#`} target="_blank">Crie a sua agora</Link>!
      </div>
    </div>
  );
}

export default Login;
