import { Button, Input } from 'antd';
import './style.scss';
import Field from 'components/Field';

function UserForm({ userData, setUserData, setStep, handleSubmit }) {

  const handleChange = (ev) => {
    return setUserData({
      ...userData,
      [ev.target.id]: ev.target.value
    })
  };

  return (
    <div className="user-form">
      <div className="form-section">
        <h4>Identificação</h4>
        <div className="row">
          <Field label="Nome">
            <Input
              id="name"
              value={userData.name}
              onChange={handleChange}
            />
          </Field>
          <Field label="E-mail">
            <Input
              id="email"
              value={userData.email}
              onChange={handleChange}
            />
          </Field>
          <Field label="Telefone">
            <Input
              id="phone"
              value={userData.phone}
              onChange={handleChange}
            />
          </Field>
        </div>
      </div>
      <Button onClick={handleSubmit}>Salvar</Button> 
    </div>
  );
}

export default UserForm;
