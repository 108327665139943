import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Field from 'components/Field';
import { Button, Input } from 'antd';
import moment from 'moment';
import { centsConvert } from 'utils/formatters';
import Currency from 'components/Field/Currency';
import { API_DATE_FORMAT_NO_GMT } from 'utils/formatters';
import './style.scss';

const dataModel = {
  description: '',
  price_cents: 0,
  quantity: 1,
  created_at: moment().format(API_DATE_FORMAT_NO_GMT) + '-03:00',
  updated_at: moment().format(API_DATE_FORMAT_NO_GMT) + '-03:00',
  price: 'R$ 0,00',
}

const DynamicForm = ({ data, setData, disabled, errors, setErrors, className }) => {
  const [dynamicData, setDynamicData] = useState([{ ...dataModel, key: uuidv4() }]);

  useEffect(() => {
    if (data.items) {
      setDynamicData(data.items.map((item) => ({ ...item, key: uuidv4() })))
    }
  }, [data.items]);

  const getStringPrice = (qtt, value) => {
    const price = qtt * value;

    return centsConvert(price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }

  const getTotalCents = (data) => {
    let totalCents = 0;

    data.map(item => {
      totalCents += item.quantity * item.price_cents;
      return true;
    })

    return totalCents;
  }

  const addAction = () =>
    setDynamicData([
      ...dynamicData,
      { ...dataModel, key: uuidv4() },
    ]);

  const handleChange = (ev, number) => {
    const key = ev.target.getAttribute('data-item-id');
    const id = ev.target.id;
    const itemData = [...dynamicData];
    const index = itemData.findIndex(item => item.key === key);
    const toChange = { [id]: ev.target.value }

    if (id === 'quantity') {
      toChange.price = getStringPrice(ev.target.value, itemData[index].price_cents)
    }

    if (id === 'price_real') {
      const nonDecimal = number * 10;
      toChange.price_cents = nonDecimal * 10;
      toChange.price = getStringPrice(itemData[index].quantity, nonDecimal)
    }

    itemData[index] = {
      ...itemData[index],
      ...toChange
    }

    setDynamicData(itemData)

    setErrors({
      ...errors,
      items: null
    })

    return setData({
      ...data,
      items: itemData,
      items_total_cents: getTotalCents(itemData)
    })
  }

  const removeAction = (key) => {
    const itemData = [...dynamicData];
    const index = itemData.findIndex(item => item.key === key);

    itemData.splice(index, 1);

    setDynamicData(itemData)

    return setData({
      ...data,
      items: itemData,
      items_total_cents: getTotalCents(itemData)
    })
  }

  return (
    <div className={`dynamic-form${className ? ` ${className}` : ''}${errors.items ? ' error' : ''}`}>
      {dynamicData.map((item) => {
        return (
          <div className="form-section" key={item.key}>
            <div className='row'>
              <Field label="Qtd." className="field-60">
                <Input
                  id="quantity"
                  data-item-id={item.key}
                  defaultValue={item.quantity}
                  disabled={disabled}
                  onBlur={handleChange}
                />
              </Field>
              <Field label="Preço" className="field-120">
                <Currency
                  prefix="R$"
                  decimalSeparator=","
                  step="0.01"
                  id="price_real"
                  data-item-id={item.key}
                  defaultValue={item.price_real || item.price_cents / 100}
                  onBlur={handleChange}
                  disabled={disabled}
                />
              </Field>
              <Field label="Total item" className="field-120">
                <Input
                  id="price"
                  data-item-id={item.key}
                  defaultValue={item.price}
                  disabled
                />
              </Field>
            </div>
            <div className='desc-add'>
              <Field label="Descrição" className="field-flex">
                <Input.TextArea
                  id="description"
                  data-item-id={item.key}
                  defaultValue={item.description}
                  onBlur={handleChange}
                  disabled={disabled}
                />
              </Field>
              <Button
                onClick={() => removeAction(item.key)}
                disabled={dynamicData.length < 2 || disabled}
              >
                Remover Item
              </Button>
            </div>
          </div>
        )
      })}
      <Button
        disabled={disabled}
        className="add-item-btn"
        onClick={addAction}
      >
        Adicionar item
      </Button>
      {errors.items && <div className="field-error">{errors.items}</div>}
    </div>
  )
};

export default DynamicForm;