import { Button, Input } from 'antd';
import './style.scss';
import Field from 'components/Field';

function PasswordForm({ userData, setUserData, setStep, handleSubmit }) {

  const handleChange = (ev) => {
    return setUserData({
      ...userData,
      [ev.target.id]: ev.target.value
    })
  };

  return (
    <div className="user-form">
      <div className="form-section">
        <h4>Troca de senha</h4>
        <div className="row">
          <Field label="Nova senha">
            <Input
              id="password"
              onChange={handleChange}
            />
          </Field>
          <Field label="Confirme a nova senha">
            <Input
              id="confirmPassword"
              onChange={handleChange}
            />
          </Field>
        </div>
      </div>
      <Button onClick={handleSubmit}>Salvar</Button> 
    </div>
  );
}

export default PasswordForm;
