import React, { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filtro, setFiltro] = useState({
    compararPor: 'vencimento',
    periodo: 'completo',
  });

  return (
    <FilterContext.Provider value={{ filtro, setFiltro }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => useContext(FilterContext);
