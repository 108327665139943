import Field from 'components/Field';
import { useSearchParams } from 'react-router-dom'
import './style.scss';
import { Button, Input } from 'antd';
import { useState } from 'react';
import { useStoreInContext } from 'store/main';
import { isValidPassword } from 'utils/formatters';

function EsqueceuSenha() {
  let [params] = useSearchParams();
  const token = params.get('token')
  const state = useStoreInContext(state => state);
  const { auth: { recoverPassword, defineNewPassword } } = state;
  const [data, setData] = useState();
  const [newPassword, setNewPassword] = useState();
  const [error, setError] = useState();

  const handleChange = (ev) => {
    setError(undefined);
    !token && setData(ev.target.value)
    token && setNewPassword(ev.target.value)
  }

  const handleSubmit = () => {
    if(!token) {  
      data && recoverPassword(state, {
        email: data
      });
    } else {
      const isValid = isValidPassword(newPassword, (err) => setError(err));
      isValid && newPassword && defineNewPassword(state, {
        token: token,
        password: newPassword
      })
    }
  }

  if (token) {
    return (
      <div className="EsqueceuSenha">
      <h1>Definir nova senha</h1>
      <div className="form">
        <div className="fields">
          <Field label="Nova senha">
            <Input
              id="password"
              type="password"
              onChange={handleChange}
              defaultValue={newPassword}
              placeholder="Sua senha"
            />
          </Field>
          {error?.length &&
            <>
              <p className="error-txt">Sua senha deve:</p>
              <ul className="error-list">
                {error.map((err, key) =>
                  <li className="error-item" key={key}>{err}</li>
                )}
              </ul>
            </>
          }
        </div>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={!newPassword}
        >
          Definir senha
        </Button>
      </div>
    </div>
    )
  }

  return (
    <div className="EsqueceuSenha">
      <h1>Recuperar senha</h1>
      <div className="form">
        <div className="fields">
          <Field label="Email">
            <Input
              id="login"
              type="text"
              onChange={handleChange}
              defaultValue={data}
              placeholder="Seu usuário"
            />
          </Field>
        </div>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={!data}
        >
          Recuperar senha
        </Button>
      </div>
    </div>
  );
}

export default EsqueceuSenha;
