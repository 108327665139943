import dayjs from 'dayjs';
import { useStoreInContext } from 'store/main';
import { useEffect, useState } from 'react';
import { Table, DatePicker, Select, Button } from 'antd';
import { API_DATE_FORMAT, DISPLAY_DATE_FORMAT } from 'utils/formatters';
import Field from 'components/Field';
import { useNavigate, useOutletContext } from 'react-router-dom';
import './style.scss';

const { RangePicker } = DatePicker;

export const invoiceStatus = [
  {
    value: 'paid',
    label: 'Paga'
  },
  {
    value: 'authorized',
    label: 'Autorizada'
  },
  {
    value: 'in_analysis',
    label: 'Em análise'
  },
  {
    value: 'draft',
    label: 'Rascunho'
  },
  {
    value: 'canceled',
    label: 'Cancelada'
  },
  {
    value: 'refunded',
    label: 'Reembolsada'
  },
  {
    value: 'expired',
    label: 'Expirada'
  },
  {
    value: 'in_protest',
    label: 'Em protesto'
  },
  {
    value: 'partially_paid',
    label: 'Parcialmente paga'
  },
  {
    value: 'pending',
    label: 'Pendente'
  },
  {
    value: 'chargeback',
    label: 'Contestada'
  },
  {
    value: 'externally_paid',
    label: 'Paga externamente'
  },
]

const columns = [
  {
    title: 'Status',
    dataIndex: 'status',
    width: 120,
    render: (value) => <span className={`col-status ${value}`}>{value ? invoiceStatus.find(item => item.value === value).label : '-'}</span>
  },
  {
    title: 'Valor',
    dataIndex: 'total',
    width: 120,
  },
  {
    title: 'Vencimento',
    dataIndex: 'due_date',
    render: (value) => {
      if (value) {
        return dayjs(value, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
      }
      return value;
    },
    width: 120,
  },
  {
    title: 'Cliente',
    dataIndex: 'name'
  },
  {
    title: 'E-mail',
    dataIndex: 'email'
  },
  {
    title: 'Criado em',
    dataIndex: 'creationDate',
    width: 150,
  }
];

function Faturas() {
  const { setDrawerOpen } = useOutletContext();
  const state = useStoreInContext(state => state);
  const { faturas: { data, fetch } } = state;
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();

  console.log('Faturas Data: ', data);

  const formatData = (invoiceData) => {
    if (!invoiceData?.items) return [];

    return invoiceData.items.map((item, key) => ({
      key: key,
      id: item.id,
      status: item.status,
      total: item.total,
      due_date: dayjs(item.due_date).format(DISPLAY_DATE_FORMAT),
      name: item.payer_name,
      email: item.payer_email,
      creationDate: item.created_at,
    }));
  };

  useEffect(() => {
    fetch(state, filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const filterByStatus = (status_filter) => {
    return setFilters({
      ...filters,
      status_filter
    });
  };

  const filterByDates = (dates) => {
    if (!dates) return setFilters({
      ...filters,
      created_at_from: undefined,
      created_at_to: undefined
    });

    const created_at_from = dayjs(dates[0]).format(API_DATE_FORMAT);
    const created_at_to = dayjs(dates[1]).format(API_DATE_FORMAT);
    return setFilters({
      ...filters,
      created_at_from,
      created_at_to
    });
  };

  return (
    <div className="Faturas">
      <div className="page-header">
        <h2 className="page-title font-normal home">
          Faturas {data?.totalItems ? `(${data?.totalItems})` : ''}
        </h2>
        <div className="page-actions">
          <Button type='dashed'>Exportar</Button>
          <Button type='default'>Emissão em lote</Button>
          <Button type='primary' onClick={() => setDrawerOpen(true)}>Nova fatura</Button>
        </div>
      </div>
      <div className="filters">
        <Field label="Data de criação">
          <RangePicker
            placeholder={['Data inicial', 'Data final']}
            format={DISPLAY_DATE_FORMAT}
            onChange={filterByDates}
            id="validate"
          />
        </Field>
        <Field label="Status">
          <Select
            style={{ width: 240 }}
            onChange={filterByStatus}
            options={invoiceStatus}
            allowClear={true}
            placeholder="Todos os status"
            id="status"
          />
        </Field>
      </div>
      <Table
        columns={columns}
        dataSource={formatData(data)}
        rowClassName={() => 'pointer'}
        onRow={(record) => ({
          onClick: () => {
            navigate(`./${record.id}`);
          },
        })}
      />
    </div>
  );
}

export default Faturas;
