import { notification } from 'antd'

// formatters

export const API_DATE_FORMAT = 'YYYY-MM-DDThh:mm:ss-03:00';
export const API_DATE_FORMAT_NO_GMT = 'YYYY-MM-DDTHH:mm:ss';
export const DISPLAY_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const DISPLAY_TIME_FORMAT = 'HH:mm';
export const REQUEST_DATE_FORMAT = 'DD/MM/YYYY';
export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';

export const centsConvert = (cents) => cents / 10;

export const formatCurrency = (value) => {
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

export const isValidPassword = (password, cb) => {
  const length = (string) => string.length >= 8 && string.length <= 16;
  const caseSensitive = (string) => string !== string.toUpperCase() && string !== string.toLowerCase();
  const nonNumeric = (string) => isNaN(string.replace(/[^a-zA-Z0-9]/g, "") * 1);
  const numbers = (string) => /\d/.test(string);
  const spaces = (string) => !(string.indexOf(' ') >= 0);
  const seqNumbers = (string) => /\d{2,}/.test(string);
  const seqLetters = (string) => /[a-zA-Z]{3,}/.test(string);
  const repeatedChars = (string) => !(/(.)\1\1+/.test(string));
  const specialChars = (string) => string.replace(/[^a-zA-Z0-9]/g, "") !== string;

  const querty = {
    0: 'qwertyuiop',
    1: 'asdfghjklç',
    2: 'zxcvbnm'
  }
  const checkQuertyLine = (string) =>
    querty[0].indexOf(string) !== -1 ||
    querty[1].indexOf(string) !== -1 ||
    querty[2].indexOf(string) !== -1;
  const checkQuerty = (string) => {
    const len = string.length;
    let res;
    for (let i = 0; i < len - 3; i++) {
      if (checkQuertyLine(string.slice(i, i + 4))) {
        res = false;
        break;
      } else {
        res = true
      }
    }
    return res;
  }

  const errors = [];

  !length(password) && errors.push('Conter entre 8 e 16 caracteres.')
  !caseSensitive(password) && errors.push('Ter pelo menos uma letra maiúscula e uma minúscula')
  !nonNumeric(password) && errors.push('Conter letras')
  !numbers(password) && errors.push('Conter números')
  !spaces(password) && errors.push('Não pode conter espaços')
  !specialChars(password) && errors.push('Deve conter ao menos um caractere especial')
  !seqNumbers(password) && errors.push('Não pode conter mais do que 2 números sequenciais')
  !seqLetters(password) && errors.push('Não pode conter mais do que 3 letras sequenciais')
  !repeatedChars(password) && errors.push('Não pode conter mais que 2 caracteres repetidos')
  !checkQuerty(password) && errors.push('Não pode conter mais que 3 letras do teclado em sequência')

  if (errors.length) {
    cb(errors)
    return false;
  }
  return true;
}

export const formatErrors = (errors) => {
  const rawList = errors?.response?.data?.errors || [];
  const errList = [];
  if (rawList) {
    if (typeof rawList !== "string") {
      Object.entries(rawList).map((error) => {
        return error[1] && Array.isArray(error[1]) ? error[1].map(err =>
          errList.push(`${error[0]}: ${err}`)
        ) : errList.push(`${error[0]}: ${error[1]}`)
      });
      errList.map((error) => notification.error({
        description: error
      }))
      return errList
    } else {
      return notification.error({
        description: rawList
      })
    }
  }
  return notification.error({
    description: errors
  });
};