import axios from 'axios'
import Cookies from 'universal-cookie'
import { createContext, useContext, useRef } from 'react'
import { createStore, useStore } from 'zustand'
import { notification } from 'antd'
import { formatErrors } from 'utils/formatters'
import { DISPLAY_DATE_FORMAT } from 'utils/formatters';
import dayjs from 'dayjs';

const cookies = new Cookies();

const StoreContext = createContext(null);

export const baseUrl = 'https://api.somosvalor.com.br';
// export const baseUrl = 'http://localhost:8080';

export const headers = (token, params) => ({
  headers: { Authorization: `Bearer ${token}` },
  params: params
});

export const StoreProvider = ({ children }) => {
  const storeRef = useRef()
  if (!storeRef.current) {
    storeRef.current = createStore((set) => ({
      ui: {
        drawers: {
          opened: false,
          toggleDrawer: (state, mode) => {
            set({
              ...state,
              ui: { 
                ...state.ui,
                drawers: {
                  ...state.ui.drawers,
                  opened: mode
                }
              }
            })
          }
        }
      },
      auth: {
        user:{},
        post: async (state, data) => {
          try {
            const res = await axios.post(`${baseUrl}/auth/v1/login`, data);
            setToken({
              code: res.data.jwe,
              expiration: res.data.expirationDateTime
            });
            setRefreshToken({
              code: res.data.refreshToken,
              expiration: res.data.expirationRefreshToken
            });
            set({
              ...state,
              auth: { 
                ...state.auth,
                user: res.data
              }
            });
          } catch (err) {
            console.error("Error in data fetch:", formatErrors(err));
          }
        },
        getNewToken: async (state, data) => {
          try {
            const res = await axios.post(`${baseUrl}/auth/v1/login/useRefreshToken`, data);
            setToken({
              code: res.data.jwe,
              expiration: res.data.expirationDateTime
            });
            setRefreshToken({
              code: res.data.refreshToken,
              expiration: res.data.expirationRefreshToken
            });
            set({
              ...state,
              auth: { 
                ...state.auth,
                roles: res.data.roles
              }
            });
          } catch (err) {
            console.error("Error in data fetch:", formatErrors(err));
          }
        },
        put: async (state, body) => {
          set({ ...state, loading: true });
          try {
            const res = await axios.put(`${baseUrl}/auth/v1/user`, body, headers(getToken(state)));
            set({
              ...state,
              auth: {
                ...state.auth,
                user: {
                  ...state.auth.user,
                  ...res.data
                }
              }
            });
            notification.success({
              description: 'Alteração salva com sucesso'
            })
          } catch (err) {
            console.error("Error in data put:", formatErrors(err));
            set({
              ...state,
              auth: {
                ...state.auth,
                error: true,
                errorData: err.message
              }
            });
          }
        },
        get: async (state) => {
          set({ ...state, loading: true });
          try {
            const res = await axios.get(`${baseUrl}/auth/v1/user/userInfo`, headers(getToken(state)));
            set({
              ...state,
              auth: {
                ...state.auth,
                user: {
                  ...state.auth.user,
                  ...res.data
                }
              }
            });
          } catch (err) {
            console.error("Error in data get:", formatErrors(err));
            set({
              ...state,
              auth: {
                ...state.auth,
                error: true,
                errorData: err.message
              }
            });
          }
        },
        password: async (state, body) => {
          set({ ...state, loading: true });
          try {
            const res = await axios.put(`${baseUrl}/auth/v1/user/password`, body, headers(getToken(state)));
            set({
              ...state,
              auth: {
                ...state.auth,
                success: res.data
              }
            });
            notification.success({
              description: 'Senha alterada com sucesso'
            })
          } catch (err) {
            console.error("Error in data put:", formatErrors(err));
            set({
              ...state,
              auth: {
                ...state.auth,
                error: true,
                errorData: err.message
              }
            });
          }
        },
        recoverPassword: async (state, body) => {
          set({ ...state, loading: true });
          try {
            const res = await axios.post(`${baseUrl}/auth/v1/user/forgetPassword`, body, {
              "Content-Type": "application/json"
            });
            notification.success({
              description: 'Senha enviada com sucesso'
            })
            if(res) {
              window.location.replace('/login')
            }
          } catch (err) {
            console.error("Error in data put:", formatErrors(err));
            set({
              ...state,
              auth: {
                ...state.auth,
                error: true,
                errorData: err.message
              }
            });
          }
        },
        defineNewPassword: async (state, body) => {
          set({ ...state, loading: true });
          try {
            const res = await axios.post(`${baseUrl}/auth/v1/user/forgetPassword/confirmation`, body, {
              "Content-Type": "application/json"
            });
            notification.success({
              description: 'Senha definida com sucesso'
            })
            if(res) {
              window.location.replace('/login')
            }
          } catch (err) {
            console.error("Error in data put:", formatErrors(err));
            set({
              ...state,
              auth: {
                ...state.auth,
                error: true,
                errorData: err.message
              }
            });
          }
        },
        logout: async (state) => {
          cookies.remove('somosValorToken');
          cookies.remove('somosValorRefreshToken');
          set({
            ...state,
            auth: {
              ...state.auth,
              user: {},
              roles: []
            }
          });
          window.location.replace('/login');
        },
      },
      faturas: {
        loading: false,
        success: false,
        errorData: false,
        data: [],
        selected: {},
        fetch: async (state, filters) => {
          set({ ...state, loading: true });
          try {
            const params = {...filters}
            const res = await axios.get(`${baseUrl}/payments/v1/invoices`, headers(getToken(state), params));
            set({ 
              ...state,
              faturas: {
                ...state.faturas,
                success: true,
                data: res.data
              },
              clientes: {
                ...state.clientes,
                selected: {}
              }
            });
          } catch (err) {
            console.error("Error in data fetch:", formatErrors(err));
            set({ 
              ...state,
              faturas: {
                ...state.faturas,
                error: true,
                errorData: err.message
              }
            });
          }
        },
        post: async (state, body) => {
          set({ ...state, loading: true });
          try {
            const res = await axios.post(`${baseUrl}/payments/v1/invoices`, body, headers(getToken(state)));
            set({
              ...state,
              faturas: {
                ...state.faturas,
                success: true,
                data: {
                  ...state.faturas.data,
                  items: [
                    res.data,
                    ...state.faturas.data.items
                  ]
                }
              },
              ui: {
                ...state.ui,
                drawers: {
                  ...state.ui.drawers,
                  opened: false
                }
              }
            });
            notification.success({
              description: 'Fatura criada com sucesso'
            })
          } catch (err) {
            console.error("Error in data post:", formatErrors(err));
            set({
              ...state,
              faturas: {
                ...state.faturas,
                error: true,
                errorData: err.message
              }
            });
          }
        },
        get: async (state, id) => {
          set({ ...state, loading: true });
          try {
            const res = await axios.get(`${baseUrl}/payments/v1/invoices/${id}`, headers(getToken(state)));
            set({
              ...state,
              faturas: {
                ...state.faturas,
                success: true,
                selected: {
                  ...res.data,
                  due_date: dayjs(res.data.due_date).format(DISPLAY_DATE_FORMAT)
                }
              }
            });
          } catch (err) {
            console.error("Error in data get:", formatErrors(err));
            set({
              ...state,
              faturas: {
                ...state.faturas,
                error: true,
                errorData: err.message
              }
            });
          }
        },
      },
      clientes: {
        loading: false,
        success: false,
        errorData: false,
        data: {
          items: []
        },
        selected: {},
        fetch: async (state, filters) => {
          set({ ...state, loading: true });
          try {
            const params = {...filters}
            const res = await axios.get(`${baseUrl}/payments/v1/customers`, headers(getToken(state), params));
            set({
              ...state,
              clientes: {
                ...state.clientes,
                success: true,
                data: res.data,
                selected: {}
              }
            });
          } catch (err) {
            console.error("Error in data fetch:", formatErrors(err));
            set({ ...state, clientes: { ...state.clientes, error: true, errorData: err.message } });
          }
        },
        post: async (state, body) => {
          set({ ...state, loading: true });
          try {
            const res = await axios.post(`${baseUrl}/payments/v1/customers`, body, headers(getToken(state)));
            set({
              ...state,
              clientes: {
                ...state.clientes,
                success: true,
                data: {
                  ...state.clientes.data,
                  items: [
                    res.data,
                    ...state.clientes.data.items
                  ]
                }
              },
              ui: {
                ...state.ui,
                drawers: {
                  ...state.ui.drawers,
                  opened: false
                }
              }
            });
            notification.success({
              description: 'Cliente criado com sucesso'
            })
          } catch (err) {
            console.error("Error in data post:", formatErrors(err));
            set({
              ...state,
              clientes: {
                ...state.clientes,
                error: true,
                errorData: err.message
              }
            });
          }
        },
        get: async (state, id) => {
          set({ ...state, loading: true });
          try {
            const res = await axios.get(`${baseUrl}/payments/v1/customers/${id}`, headers(getToken(state)));
            set({
              ...state,
              clientes: {
                ...state.clientes,
                success: true,
                selected: res.data
              }
            });
          } catch (err) {
            console.error("Error in data get:", formatErrors(err));
            set({
              ...state,
              clientes: {
                ...state.clientes,
                error: true,
                errorData: err.message
              }
            });
          }
        },
      },
      users: {
        loading: false,
        success: false,
        errorData: false,
        data: {
          items: []
        },
        fetch: async (state, accountId) => {
          set({ ...state, loading: true });
          try {
            const res = await axios.get(`${baseUrl}/payments/v1/${accountId}/user_invites`, headers(getToken(state)));
            set({ 
              ...state,
              users: {
                ...state.users,
                success: true,
                data: res.data
              },
              clientes: {
                ...state.clientes,
                selected: {}
              }
            });
          } catch (err) {
            console.error("Error in data fetch:", formatErrors(err));
            set({ 
              ...state,
              users: {
                ...state.users,
                error: true,
                errorData: err.message
              }
            });
          }
        }
      }
  }))
  }
  return (
    <StoreContext.Provider value={storeRef.current}>
      {children}
    </StoreContext.Provider>
  )
}

const setToken = (tokenData) => {
  const expiration = new Date(tokenData.expiration);
  cookies.set('somosValorToken', tokenData.code, { expires: expiration });
}

const setRefreshToken = (tokenData) => {
  const expiration = new Date(tokenData.expiration);
  cookies.set('somosValorRefreshToken', tokenData.code, { expires: expiration });
}

export const getToken = (state) => {
  const token = cookies.get('somosValorToken');
  const refreshToken = cookies.get('somosValorRefreshToken');
  if(token) {
    return token;
  }
  if(refreshToken) {
    state.auth.getNewToken(state, { refreshToken: refreshToken });
    setTimeout(function() {
      return getToken(state)
    }, 1000);
  }
  return window.location.replace('/login');
};

export const selectDataById = (dataList, id) =>
  dataList ? dataList.find((item) => item.id === id) : {};


export const useStoreInContext = (selector) => {
  const store = useContext(StoreContext)
  if (!store) {
    throw new Error('Missing StoreProvider')
  }
  return useStore(store, selector)
}
