import { Link, Outlet } from "react-router-dom";
import loginImg from "../../../assets/images/login.png";
import './style.scss';
    
const AuthLayout = ({image}) => {
  return (
    <div className="auth-page">
      <div className="auth-main-content">
        <Link to={'/'} className="auth-logo">
          <img src="/logo.svg" alt="Somos Valor" />
        </Link>
        <div className="auth-card">
          <div className="auth-image">
            {image ? image : <img src={loginImg} alt="" />}
          </div>
          <div className="auth-page-content">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;