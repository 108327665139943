import { 
  UserOutlined,
} from '@ant-design/icons';
import './style.scss';
import { Link } from 'react-router-dom';

const UserMenu = ({ userData }) => {
  return (
    <Link to={'/usuario'} className="userMenu">
      {userData.name} <UserOutlined />
    </Link>
  );
};

export default UserMenu;