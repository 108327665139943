export const options = {
    plugins: {
        legend: {
            display: false,
        }
    },
};

export const valorRecebidoGeralOptions = {
    ...options,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: {
          font: {
            size: 10,
          },
          color: '#CCC'
        }
      },
      x: {
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: {
          font: {
            size: 10,
          },
          color: '#CCC'
        }
      },
    },
  };