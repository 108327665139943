export const getPercentageFromArr = (arr, index) => {
    const total = arr.reduce((a, b) => a + b);
    return arr[index] && total > 0 ? (arr[index] / total * 100).toFixed(1) : 0;
}

export const getPerformanceFromArr = (arr, index, invert) => {
    const modifier = invert ? -1 : 1;
    const current = arr[0].data[index];
    const last = arr[1].data[index];
    const total = current && last ? (((current * 100 / last) - 100) * modifier).toFixed(1) : 0;
    const status = total > 0 ? 'MELHOR' : 'PIOR';
    return {
      text: `${total}% ${status}`,
      status: status
    }
  }