import React, { useEffect, useState } from 'react';
import './styles.scss';
import { formatCurrency } from 'utils/formatters';
import dayjs from 'dayjs';
import { Select } from 'antd';
import { useFilter } from 'contexts/FilterContext';
import { useSpring, animated } from 'react-spring';

const AnimatedNumber = ({ value }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: value,
    delay: 50,
    config: { mass: 1, tension: 700, friction: 20 }
  });

  return <animated.div>{number.to(n => formatCurrency(n))}</animated.div>;
};

const Faturamento = ({ faturasPendentes, faturasVencidas, faturasPagas }) => {
  const { filtro, setFiltro } = useFilter();

  const [amountFaturamento, setAmountFaturamento] = useState({
    previstas: 0,
    confirmadas: 0,
    recebidas: 0,
  });

  const calcularAmountFaturamento = () => {
    const hoje = dayjs();
    const ultimos30Dias = hoje.subtract(30, 'day');
    const ultimos60Dias = hoje.subtract(60, 'day');
    const ultimos90Dias = hoje.subtract(90, 'day');
    const inicioDoAno = hoje.startOf('year');

    const formatarData = (data) => dayjs(data);

    const obterDataComparacao = (fatura) => {
      const data = filtro.compararPor === 'vencimento' ? formatarData(fatura.due_date) : formatarData(fatura.created_at_iso);
      return data;
    };

    const filtrarFaturasPorPeriodo = (faturas) => {
      return faturas.filter(fatura => {
        const dataComparacao = obterDataComparacao(fatura);
        if (filtro.periodo === 'completo') {
          return true;
        } else if (filtro.periodo === '30dias') {
          return dataComparacao.isAfter(ultimos30Dias);
        } else if (filtro.periodo === '60dias') {
          return dataComparacao.isAfter(ultimos60Dias);
        } else if (filtro.periodo === '90dias') {
          return dataComparacao.isAfter(ultimos90Dias);
        } else if (filtro.periodo === 'esteano') {
          return dataComparacao.isAfter(inicioDoAno);
        }
        return true;
      });
    };

    const faturasPendentesFiltradas = filtrarFaturasPorPeriodo(faturasPendentes);
    const faturasVencidasFiltradas = filtrarFaturasPorPeriodo(faturasVencidas);
    const faturasPagasFiltradas = filtrarFaturasPorPeriodo(faturasPagas);

    const totalPrevistas = faturasPendentesFiltradas.reduce((sum, fatura) => sum + fatura.total_cents, 0) +
      faturasVencidasFiltradas.reduce((sum, fatura) => sum + fatura.total_cents, 0);
    const totalRecebidas = faturasPagasFiltradas.reduce((sum, fatura) => sum + fatura.total_cents, 0);
    const totalConfirmadas = totalPrevistas + totalRecebidas;

    setAmountFaturamento({
      previstas: totalPrevistas / 100,
      confirmadas: totalConfirmadas / 100,
      recebidas: totalRecebidas / 100
    });
  };

  useEffect(() => {
    calcularAmountFaturamento();
  }, [filtro, faturasPendentes, faturasVencidas, faturasPagas]);

  return (
    <div className="faturamento-component">
      <div className="">
        <div className='flex header-faturamento'>
          <h3 className="chartTitle">Faturamento</h3>
          <div className="filters-faturamento flex">
            <div>
              <p>Comparar por:</p>
              <Select
                value={filtro.compararPor}
                onChange={(value) => setFiltro({ ...filtro, compararPor: value })}
                style={{ width: '15rem' }}
                options={[
                  { value: 'vencimento', label: 'Data de Vencimento' },
                  { value: 'emissao', label: 'Data de Emissão' }
                ]}
              />
            </div>
            <div>
              <p>Período:</p>
              <Select
                value={filtro.periodo}
                onChange={(value) => setFiltro({ ...filtro, periodo: value })}
                style={{ width: '15rem' }}
                options={[
                  { value: 'completo', label: 'Período Completo' },
                  { value: '30dias', label: 'Últimos 30 Dias' },
                  { value: '60dias', label: 'Últimos 60 Dias' },
                  { value: '90dias', label: 'Últimos 90 Dias' },
                  { value: 'esteano', label: 'Este Ano' },
                ]}
              />
            </div>
          </div>
        </div>
        <div className='flex containerNumberLabel'>
          <div className="numberLabel blue">
            <div className="number"><AnimatedNumber value={amountFaturamento.confirmadas} /></div>
            <div className="label">Confirmadas</div>
          </div>
          <div className="numberLabel orange">
            <div className="number"><AnimatedNumber value={amountFaturamento.previstas} /></div>
            <div className="label">Previstas</div>
          </div>
          <div className="numberLabel green">
            <div className="number"><AnimatedNumber value={amountFaturamento.recebidas} /></div>
            <div className="label">Recebidas</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faturamento;
