import { Button, Tooltip } from 'antd';
import './style.scss';
import { MailOutlined, PrinterOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

function ShareFatura({ data }) {
  const {
    payer_phone_prefix,
    payer_phone,
    payer_name,
    account_name,
    payer_email,
    secure_url
  } = data || {};

  const phoneNumber = `${payer_phone_prefix}${payer_phone}`;
  const message = `Olá ${payer_name}, Acesse sua fatura através do link: ${secure_url}`;
  const assunto = `Fatura ${account_name}`;
  
  return (
    <div className="share-fatura">
      <h4 className="section-title">Compartilhar / Baixar Fatura</h4>
      <Tooltip title="Enviar pelo Whatsapp">
        <Link to={`https://wa.me/${phoneNumber}/?text=${message}`} target="_blank">
          <Button icon={<WhatsAppOutlined />}>Whatsapp</Button>
        </Link>
      </Tooltip>
      <Tooltip title="Enviar por email">
        <Link to={`mailto:${payer_email}?subject=${assunto}&body=${message}`} target="_blank">
          <Button icon={<MailOutlined />}>Email</Button>
        </Link>
      </Tooltip>
      <Tooltip title="Fazer download ou imprimir">
        <Link to={`${secure_url}/bank_slip`} target="_blank">
          <Button icon={<PrinterOutlined />}>Baixar/Imprimir</Button>
        </Link>
      </Tooltip>
    </div>
  );
}

export default ShareFatura;
