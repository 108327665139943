import React, { useEffect, useState } from 'react';
import './styles.scss';
import { getPercentageFromArr, getPerformanceFromArr } from 'utils/calculate';
import { Doughnut } from 'react-chartjs-2';
import { options } from 'utils/options';
import { formatCurrency } from 'utils/formatters';

const Pizza = ({ faturasPendentes, faturasPagas, faturasVencidas }) => {
  const [pagamentosPorStatus, setPagamentosPorStatus] = useState({
    labels: [
      'Pagos',
      'Vencidos',
      'À vencer'
    ],
    datasets: [{
      label: 'Pgtos./Status no mês atual',
      data: [0, 0, 0],
      backgroundColor: [
        '#3BCC99',
        '#EA7B7B',
        '#F2F2F2'
      ],
      hoverOffset: 4,
      cutout: '90%'
    },
    {
      label: 'Pgtos./Status no mês passado',
      data: [0, 0, 0],
      backgroundColor: [
        '#3BCC9966',
        '#EA7B7B66',
        '#F2F2F266'
      ],
      hoverOffset: 4,
      cutout: '90%'
    }]
  });

  const calcularPagamentosPorStatus = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const lastMonth = (currentMonth === 0) ? 11 : currentMonth - 1;
    const lastMonthYear = (currentMonth === 0) ? currentYear - 1 : currentYear;

    const isCurrentMonth = (date) => {
      const d = new Date(date);
      return d.getMonth() === currentMonth && d.getFullYear() === currentYear;
    };

    const isLastMonth = (date) => {
      const d = new Date(date);
      return d.getMonth() === lastMonth && d.getFullYear() === lastMonthYear;
    };

    const totalPagos = faturasPagas
      .filter(fatura => {
        const isCurrent = isCurrentMonth(fatura.due_date);
        console.log('Fatura Pagos:', fatura, 'isCurrent:', isCurrent);
        return isCurrent;
      })
      .reduce((sum, fatura) => {
        console.log('Valor a somar:', fatura.total_cents);
        return sum + fatura.total_cents;
      }, 0);

    const totalVencidos = faturasVencidas
      .filter(fatura => isCurrentMonth(fatura.due_date))
      .reduce((sum, fatura) => sum + fatura.total_cents, 0);

    const totalAPagar = faturasPendentes
      .filter(fatura => isCurrentMonth(fatura.due_date))
      .reduce((sum, fatura) => sum + fatura.total_cents, 0);

    const totalPagosLastMonth = faturasPagas
      .filter(fatura => isLastMonth(fatura.due_date))
      .reduce((sum, fatura) => sum + fatura.total_cents, 0);

    const totalVencidosLastMonth = faturasVencidas
      .filter(fatura => isLastMonth(fatura.due_date))
      .reduce((sum, fatura) => sum + fatura.total_cents, 0);

    const totalAPagarLastMonth = faturasPendentes
      .filter(fatura => isLastMonth(fatura.due_date))
      .reduce((sum, fatura) => sum + fatura.total_cents, 0);

    setPagamentosPorStatus(prevState => ({
      ...prevState,
      datasets: [
        {
          ...prevState.datasets[0],
          data: [totalPagos / 100, totalVencidos / 100, totalAPagar / 100]
        },
        {
          ...prevState.datasets[1],
          data: [totalPagosLastMonth / 100, totalVencidosLastMonth / 100, totalAPagarLastMonth / 100]
        }
      ]
    }));
  };


  useEffect(() => {
    calcularPagamentosPorStatus();
  }, [faturasPendentes, faturasVencidas, faturasPagas]);

  return (
    <div className="col-6 ringChart">
      <div className="ringChart-labels">
        <div className="ringChart-label-item green">
          <h5>PAGOS</h5>
          <div className="amount">
            <b>{formatCurrency(pagamentosPorStatus.datasets[0].data[0])}</b>
            <div>{getPercentageFromArr(pagamentosPorStatus.datasets[0].data, 0)}%</div>
          </div>
        </div>
        <div className="ringChart-label-item red">
          <h5>VENCIDOS</h5>
          <div className="amount">
            <b>{formatCurrency(pagamentosPorStatus.datasets[0].data[1])}</b>
            <div>{getPercentageFromArr(pagamentosPorStatus.datasets[0].data, 1)}%</div>
          </div>
        </div>
        <div className="ringChart-label-item grey">
          <h5>À VENCER</h5>
          <div className="amount">
            <b>{formatCurrency(pagamentosPorStatus.datasets[0].data[2])}</b>
            <div>{getPercentageFromArr(pagamentosPorStatus.datasets[0].data, 2)}%</div>
          </div>
        </div>
      </div>
      <div className="ringChart-chart">
        <h3>Pagamentos por Status no mês atual</h3>
        <Doughnut data={pagamentosPorStatus} options={options} />
      </div>
    </div>
  );
}

export default Pizza;
