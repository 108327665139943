import { useStoreInContext } from 'store/main';
import './style.scss';
import { Button, Tabs } from 'antd';
import UserForm from 'components/Forms/UserForm';
import PasswordForm from 'components/Forms/PasswordForm';
import { useState } from 'react';

function User() {
  const state = useStoreInContext(state => state);
  const { auth: { user, put, logout } } = state; // Incluindo a função de logout
  const [userData, setUserData] = useState(user);

  const handleSubmit = () => {
    put(state, userData);
  };

  const handleLogout = () => {
    logout(state);
    window.location.replace('/login');
  };

  return (
    <div className="User">
      <div className="page-header">
        <h2 className="page-title">
          Gerenciamento de contas
        </h2>
        <Button type='primary' onClick={handleLogout}>Logout</Button>
      </div>
      <Tabs
        items={[
          {
            label: 'Conta',
            key: '1',
            children: <UserForm userData={userData} setUserData={setUserData} handleSubmit={handleSubmit} />
          },
          {
            label: 'Segurança',
            key: '2',
            children: <PasswordForm />
          },
        ]}
      />
    </div>
  );
}

export default User;
