import { Tooltip } from 'antd';
import './style.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

const Field = ({children, label, tooltip, errors, className}) =>
  (
    <div className={`field-wrapper${className ? ` ${className}` : ''}${errors ? ' error' : ''}`}>
      {label && 
        <div className="field-label">
          {label}{' '}
          {tooltip && 
            <Tooltip placement="topRight" title={tooltip}>
              <InfoCircleOutlined />
            </Tooltip>
          }
        </div>
      }
      <div className="field-content">
        {children}
        {errors && <div className="field-error">{errors}</div>}
      </div>
    </div>
  );

export default Field;