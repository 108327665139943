import IntlCurrencyInput from "react-intl-currency-input"
import './style.scss';

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const Currency = (props) => {

  const customProps = {
    ...props
  }

  return (
    <IntlCurrencyInput
      {...customProps}
      currency="BRL"
      config={currencyConfig}
    />
  )
};

export default Currency;