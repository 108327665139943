import { useStoreInContext } from 'store/main';
import './style.scss';
import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import MainForm from 'components/Forms';
import ShareFatura from 'components/Forms/FaturaForm/ShareFatura';

function FaturaItem() {
  let { id } = useParams();
  const state = useStoreInContext(state => state);
  const { faturas } = state;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      setLoading(true);
      faturas.get(state, id).finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="FaturaItem">
      <h2 className="page-title">
        Visualizar fatura
      </h2>
      {loading ? (
        <div className="loading-container">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {id !== undefined && <MainForm id={id} initialStep={1} submitLabel="Salvar fatura" justInvoice={true} />}
          <ShareFatura data={faturas.selected} />
        </>
      )}
    </div>
  );
}

export default FaturaItem;
