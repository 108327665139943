import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useStoreInContext } from 'store/main';
import FaturaForm from './FaturaForm';
import ClienteForm from './ClienteForm';
import SearchClient from './ClienteForm/Search';
import ShareFatura from './FaturaForm/ShareFatura';
import { API_DATE_FORMAT_NO_GMT } from 'utils/formatters';
import './style.scss';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function MainForm({ initialStep = 1, id, submitLabel, justClient, justInvoice, clientId }) {
  const state = useStoreInContext(state => state);
  const { faturas, clientes } = state;
  const [ step, setStep ] = useState(initialStep);
  const [ clientData, setClientData ] = useState({...clientes.selected})
  const [ data, setData ] = useState({
    status: "pending",
    currency: "BRL",
    email: clientes.selected?.email,
    payer: {
      name: clientes.selected?.name,
      cpf_cnpj: clientes.selected?.cpf_cnpj,      
    }
  });
  const prevAmount = usePrevious({items_total_cents: data.items_total_cents});

  useEffect(() => {
    if(clientId) {
      const getClient = clientes.selected;
      if(getClient) setClientData(getClient);
    }
    if(id) {
      const getClient = clientes.selected;
      const getInvoice = faturas.selected;
      if(getClient) setClientData(getClient);
      if(getInvoice) setData(getInvoice);
    }
  }, [clientes.selected, faturas.selected, id, clientId])

  useEffect(() => {
    if(prevAmount?.items_total_cents !== data?.items_total_cents) {
      setData({
        ...data,
        total_cents: data.items_total_cents
      })
    }
  }, [data, prevAmount])

  const handleSubmit = (body = data, type = 'fatura') => {
    if(type === 'fatura') {
      delete body.price_real;

      faturas.post(state, {
        ...body,
        updated_at: moment().format(API_DATE_FORMAT_NO_GMT) + '-03:00'
      });
      return console.log('send fatura', body);
    }

    clientes.post(state, body);
    return console.log('send cliente', body);
  }

  const defaultProps = {
    id,
    clientId,
    handleSubmit,
    data,
    setData,
    setStep,
    clientData,
    setClientData,
    submitLabel,
    justClient,
    justInvoice
  }

  return (
    <div className="main-form drawer-form">
      {
        step === 1 && (
          <FaturaForm {...defaultProps}/>
        )
      }
      {
        step === 2 && (
          <>
            <SearchClient {...defaultProps}/>
          </>
        )
      }
      {
        step === 3 && (
          <>
            <ClienteForm {...defaultProps}/>
          </>
        )
      }
      {
        step === 4 && (
          <>
            <ShareFatura data={faturas.selected}/>
          </>
        )
      }
    </div>
  );
}

export default MainForm;
