import { useState } from 'react';
import Field from 'components/Field';
import { Button, Select } from 'antd';
import './style.scss';
import axios from 'axios';
import { baseUrl, getToken, headers, useStoreInContext } from 'store/main';

let timeout;
let currentValue;

function SearchClient({ data, setStep, handleSubmit }) {
  const state = useStoreInContext(state => state);
  const [clienteList, setClienteList] = useState([]);
  const [search, setSearch] = useState([]);
  const [value, setValue] = useState();

  const fetch = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
  
    const getClientes = async () => {
      const res = await axios.get(`${baseUrl}/payments/v1/customers`, headers(getToken(state), {
        query: value
      }));
      if(res?.data) {
        if (currentValue === value) {
          setClienteList(res.data.items);
          return callback(res.data?.items.map((item) => ({
                value: `${item?.name} | ${item?.email}`,
                label: `${item?.name} | ${item?.email}`,
          })))
        }
      }
    };
    if (value) {
      timeout = setTimeout(getClientes, 300);
    } else {
      callback([]);
    }
  };

  const handleSearch = (newValue) => {
    fetch(newValue, setSearch);
  };

  const handleChange = (newValue) => {
    newValue && setValue(newValue.split(' | ')[1]);
  };

  const getClientDataByEmail = (list = clienteList, email) => list.find(client => client.email === email);

  const prepareToSubmit = () => {
    const clientData = getClientDataByEmail(clienteList, value);
    return handleSubmit({
      ...data, 
      id: clientData.id ,
      email: clientData.email,
      cc_emails: clientData.cc_emails,
      payer: {
        cpf_cnpj: clientData.cpf_cnpj,
        name: clientData.name,
        email: clientData.email,
        phone: clientData.phone,
        phone_prefix: clientData.phone_prefix,
        address: {
          zip_code: clientData.zip_code,
          street: clientData.street,
          district: clientData.district,
          city: clientData.city,
          state: clientData.state,
          number: clientData.number,
          complement: clientData.complement,
        }
      }
    });
  }

  return (
    <div className="busca-cliente">
      <h4>Encontre ou crie um cliente</h4>
      <Field label="Busca de clientes">
        <Select
          showSearch
          suffixIcon={null}
          value={value}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={null}
          options={search}
          placeholder="Insira número do CPF, CNPJ, Nome, Email..."
        />
      </Field>
      {value ? 
        <Button onClick={prepareToSubmit}>Gerar fatura para este cliente</Button> 
        : 
        <Button onClick={() => setStep(3)}>Cadastrar novo cliente</Button>}
    </div>
  );
}

export default SearchClient;
