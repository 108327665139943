import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Line } from 'react-chartjs-2';
import { valorRecebidoGeralOptions } from 'utils/options';
import { Select } from 'antd';

const EmitidosRecebidos = ({ faturasEmitidasPorMes }) => {
    const [emitidoRecebido, setEmitidoRecebido] = useState({
        labels: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
        datasets: [
            {
                label: 'Recebidos',
                backgroundColor: '#72CCAE',
                borderColor: '#00A653',
                fill: true,
                borderWidth: 1,
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                lineTension: 0.4,
            },
            {
                label: 'Emitidos',
                backgroundColor: '#B9D5F0',
                borderColor: '#00ACEC',
                fill: true,
                borderWidth: 1,
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                lineTension: 0.4,
            },
        ]
    });

    const [tipoDado, setTipoDado] = useState('valor'); // 'valor' ou 'quantidade'
    const [periodo, setPeriodo] = useState('completo'); // 'completo', '30dias', '60dias', '90dias', 'esteano'

    const calcularEmitidoRecebido = () => {
        const emitidoPorMes = Array(12).fill(0);
        const recebidoPorMes = Array(12).fill(0);

        const somarFaturasPorMes = (faturas, targetArray, tipo) => {
            Object.keys(faturas).forEach(monthYear => {
                const [month, year] = monthYear.split('-').map(Number);
                faturas[monthYear].forEach(fatura => {
                    if (year === new Date().getFullYear()) {
                        if (tipo === 'valor') {
                            targetArray[month - 1] += fatura.total_cents; // Soma o valor total das faturas do mês
                        } else if (tipo === 'quantidade') {
                            targetArray[month - 1] += 1; // Soma a quantidade de faturas do mês
                        }
                    }
                });
            });
        };

        // Soma as faturas pagas, vencidas e pendentes para emitidos
        somarFaturasPorMes(faturasEmitidasPorMes.pagas, emitidoPorMes, tipoDado);
        somarFaturasPorMes(faturasEmitidasPorMes.vencidas, emitidoPorMes, tipoDado);
        somarFaturasPorMes(faturasEmitidasPorMes.pendentes, emitidoPorMes, tipoDado);

        // Soma apenas as faturas pagas para recebidos
        somarFaturasPorMes(faturasEmitidasPorMes.pagas, recebidoPorMes, tipoDado);

        // Converte os valores para reais se o tipo de dado for 'valor'
        const emitidoPorMesFinal = tipoDado === 'valor' ? emitidoPorMes.map(value => value / 100) : emitidoPorMes;
        const recebidoPorMesFinal = tipoDado === 'valor' ? recebidoPorMes.map(value => value / 100) : recebidoPorMes;

        // Atualiza o dataset de emitidos e recebidos no estado
        setEmitidoRecebido(prevState => ({
            ...prevState,
            datasets: [
                {
                    ...prevState.datasets[0],
                    data: recebidoPorMesFinal
                },
                {
                    ...prevState.datasets[1],
                    data: emitidoPorMesFinal
                }
            ]
        }));
    };

    useEffect(() => {
        calcularEmitidoRecebido()
    }, [faturasEmitidasPorMes, tipoDado]);

    return (
        <div className="col-6">
            <div className='header-emitido-recebido'>
                <h3 className="chartTitle">Emitidos/Recebidos</h3>
                <div className='filtro-tipo'>
                    <p>Tipo de Dado:</p>
                    <Select
                        value={tipoDado}
                        onChange={(value) => setTipoDado(value)}
                        style={{ width: '15rem' }}
                        options={[
                            { value: 'valor', label: 'Valor em Reais' },
                            { value: 'quantidade', label: 'Quantidade de Faturas' }
                        ]}
                    />
                </div>
            </div>
            <Line data={emitidoRecebido} options={valorRecebidoGeralOptions} />
        </div>
    );
}

export default EmitidosRecebidos;
