import ReactDOM from "react-dom/client";
import { ConfigProvider } from 'antd';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { StoreProvider } from "store/main";

import Layout from "./components/Layouts/Default";
import AuthLayout from "./components/Layouts/Authentication";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Clientes from "./pages/Clientes";
import ClienteItem from "./pages/Clientes/Item";
import Faturas from "./pages/Faturas";
import FaturaItem from "./pages/Faturas/Item";
import Error from "./pages/Error";

import './index.scss';
import User from "pages/Users";
import EsqueceuSenha from "pages/Login/EsqueceuSenha";
import { FilterProvider } from "contexts/FilterContext";

export default function App() {
  return (
    <StoreProvider>
      <FilterProvider>
        <ConfigProvider
          theme={{  
            token: {
              fontFamily: 'Poppins',
              fontSize: 16,
              borderRadius: 16,
              colorPrimary: '#01291B',
            },
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route path="login/" element={<AuthLayout />}>
                <Route index element={<Login />} />
                <Route path="esqueceu-senha" element={<EsqueceuSenha />} />
                <Route path="updatePassword" element={<EsqueceuSenha />} />
              </Route>
              <Route path="/" element={<Layout />}>
                <Route index element={<Navigate to="/login" replace={true} />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="clientes/">
                  <Route index element={<Clientes />} />
                  <Route path="lista" element={<Clientes />} />
                  <Route path=":id" element={<ClienteItem />} />
                </Route>
                <Route path="faturas/">
                  <Route index element={<Faturas />} />
                  <Route path="lista" element={<Faturas />} />
                  <Route path=":id" element={<FaturaItem />} />
                </Route>
                <Route path="usuario" element={<User />} />
              </Route>
              <Route path="logout" element={<Navigate to="/login" replace={true} />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </BrowserRouter>
        </ConfigProvider>
      </FilterProvider>
    </StoreProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);