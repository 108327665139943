import { useStoreInContext } from 'store/main';
import './style.scss';
import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import MainForm from 'components/Forms';

function ClienteItem() {
  let { id } = useParams();
  const state = useStoreInContext(state => state);
  const { clientes } = state;

  useEffect(() => {
    id && clientes.get(state, id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div className="ClienteItem">
      <h2 className="page-title">
        Editar cliente
      </h2>
      {id !== undefined && <MainForm id={id} initialStep={3} submitLabel="Salvar cliente"/>}
    </div>
  );
}

export default ClienteItem;
