import { useEffect, useState } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { Drawer, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Menu from "../../Menu";
import './style.scss';
import FloatButton from "components/FloatButton";
import MainForm from "components/Forms";
import { getToken, useStoreInContext } from "store/main";
    
const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = useStoreInContext(state => state);
  const { auth: { user, get }, ui: { drawers } } = state;
  const token = getToken(state);
  const isLoginPage = location.pathname === '/';
  const [loaded, setLoaded] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [drawerProps, setDrawerProps] = useState({
    title: 'Nova fatura',
    content: <MainForm/>,
    width: 640
  });

  useEffect(() => {
    if (!loaded && token && !user.userName) {
      get(state)
      setLoaded(true)
    }
    if (!token) {
      navigate('/login')
    }
    if (user.userName) {
      setAuthenticated(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, user.userName])

  const setDrawerOpen = (mode) => drawers.toggleDrawer(state, mode)

  return (
    <div className={`default-page${isLoginPage ? ' login-page' : ''}`}>
      <div className="default-page-content">
        {!isLoginPage && <Menu location={location} userData={user}/>}
        <div className="default-page-card">
          {location.pathname !== '/faturas' && <Button className="back-btn" onClick={() => navigate(-1)}><ArrowLeftOutlined /></Button>}
          {!authenticated ? 'carregando...' : <Outlet context={{
            setDrawerOpen,
            setDrawerProps
          }} />}
        </div>
        <FloatButton action={(clientId) => {
          setDrawerProps({
            ...drawerProps,
            content: {
              ...drawerProps.content,
              props: {
                clientId,
                submitLabel: clientId && 'Gerar fatura para este cliente'
              }
            }
          })
          setDrawerOpen(true)
        }}/>
        <Drawer
          placement="right"
          open={drawers.opened}
          onClose={() => setDrawerOpen(false)}
          maskClosable={true}
          destroyOnClose={true}
          width={520}
          {...drawerProps}
        >{drawerProps?.content}</Drawer>
      </div>
    </div>
  );
};

export default Layout;