import dayjs from 'dayjs';
import { useStoreInContext } from 'store/main';
import { useEffect, useState } from 'react';
import { Button, DatePicker, Input, Table } from 'antd';
import Field from 'components/Field';
import { API_DATE_FORMAT, DISPLAY_DATETIME_FORMAT, DISPLAY_DATE_FORMAT } from 'utils/formatters';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import MainForm from 'components/Forms';
import './style.scss';

const { RangePicker } = DatePicker;
const { Search } = Input;

const getColumns = (setDrawerOpen, setDrawerProps, drawerProps) => [
  {
    title: 'Cliente',
    dataIndex: 'name',
    render: (value) => <span className='col-name'>{value}</span>
  },
  {
    title: 'E-mail',
    dataIndex: 'email'
  },
  {
    title: 'Criado em',
    dataIndex: 'creationDate',
    render: (value) => {
      if (value) {
        return dayjs(value).format(DISPLAY_DATETIME_FORMAT)
      }
      return value;
    },
    width: 180
  },
  {
    title: 'Ações',
    dataIndex: 'id',
    render: (value) => {
      return <Link onClick={() => {
        setDrawerProps({
          ...drawerProps,
          content: {
            ...drawerProps.content,
            props: {
              clientId: value,
              submitLabel: value && 'Gerar fatura para este cliente'
            }
          }
        })
        setDrawerOpen(true)
      }}>Nova Fatura</Link>
    },
    width: 120
  },
];

function Clientes() {
  const { setDrawerOpen, setDrawerProps } = useOutletContext();
  const state = useStoreInContext(state => state);
  const { clientes: { data, fetch } } = state;
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();

  const formatData = (clientData) => {
    if(!clientData?.items) return [];

    return clientData.items.map((item, key) => ({
      key: key,
      id: item.id,
      name: item.name,
      email: item.email,
      status: item.status,
      creationDate: item.created_at,
    }))
  }

  useEffect(() => {
    fetch(state, filters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const onSearch = (query) => {
    return setFilters({
      ...filters,
      query: query
    })
  };

  const filterByDates = (dates) => {
    if(!dates) return setFilters({
      ...filters,
      created_at_from: undefined,
      created_at_to: undefined
    });

    const created_at_from = dayjs(dates[0]).format(API_DATE_FORMAT);
    const created_at_to = dayjs(dates[1]).format(API_DATE_FORMAT);
    return setFilters({
      ...filters,
      created_at_from,
      created_at_to
    })    
  };

  const handleNewClient = () => {
    setDrawerProps({
      title: 'Novo cliente',
      content: <MainForm initialStep={3} submitLabel="Cadastrar cliente" justClient={true}/>,
      onClose: () => { 
        setDrawerOpen(false)
        return setDrawerProps({
          title: 'Nova fatura',
          content: <MainForm/>
        })}
    });
    return setDrawerOpen(true);
  }
  
  const drawerProps = {
    title: 'Nova fatura',
    content: <MainForm/>,
    width: 640
  }

  const columns = getColumns(setDrawerOpen, setDrawerProps, drawerProps);

  return (
    <div className="Clientes">
      <div className="page-header">
        <h2 className="page-title">
          Clientes {data?.totalItems ? `(${data?.totalItems})` : ''}
        </h2>
        <div className="page-actions">
          <Button type='dashed'>Exportar</Button>
          <Button type='primary' onClick={handleNewClient}>Novo cliente</Button>
        </div>
      </div>
      <div className="filters">
        <Field label="Data de criação">
          <RangePicker
            placeholder={['Data inicial', 'Data final']}
            format={DISPLAY_DATE_FORMAT}
            onChange={filterByDates}
            id="validate"
          />
        </Field>
        <Field label="Busca">
          <Search
            placeholder="Pesquise por nome, ID, etc..."
            onSearch={onSearch}
          />
        </Field>
      </div>
      <Table 
      columns={columns} 
      dataSource={formatData(data)} 
      onChange={onChange} 
      rowClassName={"pointer"}
      onRow={(record) => ({
        onClick: () => {
          navigate(`./${record.id}`);
        },
      })}
      />
    </div>
  )
}

export default Clientes;
