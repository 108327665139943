import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { LinkOutlined, MenuOutlined, PieChartOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import './style.scss';
import UserMenu from "./UserMenu";
import { baseUrl, getToken, headers, useStoreInContext } from 'store/main';

const Menu = ({ userData }) => {
  const state = useStoreInContext(state => state);
  const [isLoading, setIsLoading] = useState(false);
  const [menuState, setMenuState] = useState('');
  const menuRef = useRef();
  const location = useLocation(); // Adicionado para monitorar a localização

  const menuItems = [
    {
      text: 'Dashboard',
      link: '/dashboard',
      icon: <PieChartOutlined />,
      className: 'dashboard',
    },
    {
      text: 'Faturas',
      link: '/faturas',
      icon: <PieChartOutlined />,
      className: 'faturas',
    },
    {
      text: 'Clientes',
      link: '/clientes',
      icon: <PieChartOutlined />,
      className: 'clientes',
    },
  ];

  const parseDuration = (durationStr) => {
    const durationRegex = /(\d+)([a-zA-Z]+)/;
    const matches = durationStr.match(durationRegex);
    if (matches) {
      const value = parseInt(matches[1], 10);
      const unit = matches[2];
      return moment.duration(value, unit);
    }
    return moment.duration(0);
  };

  const handleConsultaRecebiveisClick = async () => {
    setIsLoading(true);
    try {
      console.log('Fazendo requisição GET para:', `${baseUrl}/anticipation/v1/cards`);
      const res = await axios.get(`${baseUrl}/anticipation/v1/cards`, headers(getToken(state)));

      // Logando a resposta completa do JSON
      console.log('Resposta completa do JSON:', res.data);

      // Extraindo dados recebidos
      const { url, createdAt, expiresIn } = res.data;

      // Logs detalhados das informações recebidas
      console.log('URL:', url);
      console.log('Created At:', createdAt);
      console.log('Expires In:', expiresIn);

      // Convertendo horário de criação para o formato atual
      const creationTime = moment(createdAt);
      console.log('Horário de Criação:', creationTime.format());

      // Convertendo a string de expiração para um momento de duração
      const expirationDuration = parseDuration(expiresIn);
      const expirationTime = creationTime.add(expirationDuration);
      console.log('Horário de Expiração:', expirationTime.format());
      console.log('Duração de Expiração:', expirationDuration);

      // Obtendo o horário atual
      const currentTime = moment();
      console.log('Horário Atual:', currentTime.format());

      // Comparando para ver se o horário atual é menor do que o horário de expiração
      if (currentTime.isBefore(expirationTime)) {
        window.open(url, '_blank'); // Abre uma nova aba com a URL
      } else {
        alert('O link expirou. Tente novamente.');
      }
    } catch (error) {
      // Tratamento de erros
      console.error('Erro ao buscar link de consulta recebíveis:', error);
      alert('Erro ao buscar link de consulta recebíveis. Tente novamente mais tarde.');
    } finally {
      // Finalizando o estado de carregamento
      setIsLoading(false);
    }
  };

  const handleMenuToggle = () => {
    if (menuState === 'open') {
      setMenuState('closing');
      setTimeout(() => {
        setMenuState('');
      }, 400); // Tempo da animação de fechamento
    } else {
      setMenuState('open');
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      handleMenuToggle();
    }
  };

  useEffect(() => {
    if (menuState === 'open') {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuState]);

  useEffect(() => {
    setMenuState(''); // Fecha o menu quando a localização mudar
  }, [location]);

  return (
    <nav className="menu">
      <Link to={'/faturas'} className="menu-logo">
        <img src="/logo.svg" alt="Somos Valor" />
        <span className="company-name">{userData.groupName}</span>
      </Link>
      
      <div className={`flex interactive ${menuState}`} ref={menuRef}>
        <MenuOutlined className='open-menu-button' style={{ color: '#FFF' }} onClick={handleMenuToggle} />
        
        <ul>
          <div className="consulta-recebiveis" onClick={handleConsultaRecebiveisClick}>
            {isLoading ? <span>Carregando...</span> : 'Consultar Antecipação'}
            <div className='icon'>
              <LinkOutlined />
            </div>
          </div>
         
          <div className='pages-menu'>
          {menuItems.map((menuItem, key) => (
            <li
            className={`${location.pathname.includes(menuItem.link) ? 'menu-active ' : ''}${menuItem.className}`}
            key={key}
            >
              <Link className="menu-item-link" to={menuItem.link} relative="path">
                <span className="menu-item-text">{menuItem.text}</span>
              </Link>
            </li>
          ))}
          </div>
          <UserMenu userData={userData} />
        
        </ul>
      
      </div>
    
    </nav>
  );
};

export default Menu;
