import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { valorRecebidoGeralOptions } from 'utils/options';

const InGeral = ({ title, faturasEmitidasPorMes}) => {
  const inadimplenciaGeral = {
    labels: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
    datasets: [
      {
        label: 'Inadimplência geral',
        backgroundColor: '#EA7B7B',
        borderColor: '#EA7B7B',
        borderWidth: 1,
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        label: 'Inadimplência geral',
        backgroundColor: '#EA7B7B',
        borderColor: '#EA7B7B',
        borderWidth: 1,
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  };

  useEffect(() => {
  }, [faturasEmitidasPorMes]);

  return (
    <div className="col-4">
      <h3 className="chartTitle">{title}</h3>
      <Bar data={inadimplenciaGeral} options={valorRecebidoGeralOptions} />
    </div>
  );
};

export default InGeral; 
