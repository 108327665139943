import { useState } from 'react';
import { Button, Input } from 'antd';
import './style.scss';
import Field from 'components/Field';

function ClienteForm({
  id,
  data,
  setData,
  clientData,
  setClientData,
  handleSubmit,
  justClient,
  submitLabel = 'Gerar fatura para este cliente'
}) {
  const [errors, setErrors] = useState({});

  const handleChange = (ev) => {
    return setClientData({
      ...clientData,
      [ev.target.id]: ev.target.value
    })
  };

  const handleEmailChange = (ev) => {
    setErrors({
      ...errors,
      email: null
    })
    setData({
      ...data,
      [ev.target.id]: ev.target.value
    })
    return setClientData({
      ...clientData,
      [ev.target.id]: ev.target.value
    })
  };

  const validate = (action) => {
    let hasErrors;
    if (!clientData.email) {
      hasErrors = true;
      setErrors({
        ...errors,
        email: 'Email é obrigatório.'
      })
    }
    if (!hasErrors){
      return action()
    }
    return;
  }

  return (
    <div className="novo-cliente">
      <div className="form-section">
        <h4>Identificação</h4>
        <div className="row">
          <Field label="CPF / CNPJ">
            <Input
              id="cpf_cnpj"
              value={clientData.cpf_cnpj}
              onChange={handleChange}
            />
          </Field>
        </div>
        <div className="row">
          <Field label="Nome / Razão social">
            <Input
              id="name"
              value={clientData.name}
              onChange={handleChange}
            />
          </Field>
        </div>
        <div className="row">
          <Field label="Email" errors={errors.email}>
            <Input
              id="email"
              value={clientData.email}
              onChange={handleEmailChange}
            />
          </Field>
          <Field label="Email secundário">
            <Input
              id="cc_emails"
              value={clientData.cc_emails}
              onChange={handleChange}
            />
          </Field>
        </div>
        <div className="row">
          <Field label="DDD + Telefone">
            <Input
              id="phone_prefix"
              value={clientData.phone_prefix}
              onChange={handleChange}
            />
            <Input
              id="phone"
              value={clientData.phone}
              onChange={handleChange}
            />
          </Field>
          <Field label="Anotações">
            <Input
              id="notes"
              value={clientData.notes}
              onChange={handleChange}
            />
          </Field>
        </div>
      </div>
      <div className="form-section">
        <h4>Endereço</h4>
        <div className="row">
          <Field label="CEP">
            <Input
              id="zip_code"
              value={clientData.zip_code}
              onChange={handleChange}
            />
          </Field>
          <div></div>
        </div>
        <div className="row">
          <Field label="Endereço">
            <Input
              id="street"
              value={clientData.street}
              onChange={handleChange}
            />
          </Field>
        </div>
        <div className="row">
          <Field label="Número">
            <Input
              id="number"
              value={clientData.number}
              onChange={handleChange}
            />
          </Field>
          <Field label="Complemento">
            <Input
              id="complement"
              value={clientData.complement}
              onChange={handleChange}
            />
          </Field>
        </div>
        <div className="row">
          <Field label="Bairro">
            <Input
              id="district"
              value={clientData.district}
              onChange={handleChange}
            />
          </Field>
          <Field label="Cidade">
            <Input
              id="city"
              value={clientData.city}
              onChange={handleChange}
            />
          </Field>
          <Field label="Estado">
            <Input
              id="state"
              value={clientData.state}
              onChange={handleChange}
            />
          </Field>
        </div>
      </div>
      <Button onClick={() => {
        if (id || justClient) { 
          validate(() => handleSubmit(clientData, 'cliente')) 
        } else { 
          validate(() => {
            handleSubmit(clientData, 'cliente')
            handleSubmit(data)
          })
        }
      }}>{submitLabel}</Button> 
    </div>
  );
}

export default ClienteForm;
