import React, { useEffect, useState } from 'react';
import './style.scss';
import { useStoreInContext } from 'store/main';
import Faturamento from 'components/Dashboard/Faturamento';
import Clientes from 'components/Dashboard/Clientes';
import Cobrancas from 'components/Dashboard/Cobrancas';
import Pizza from 'components/Dashboard/Pizza';
import EmitidosRecebidos from 'components/Dashboard/EmitidosRecebidos';
import { FilterProvider, useFilter } from 'contexts/FilterContext';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from 'chart.js';
import ValGeral from 'components/Dashboard/Barras/ValGeral';
import InPeriodo from 'components/Dashboard/Barras/InPeriodo';
import InGeral from 'components/Dashboard/Barras/InGeral';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  PointElement,
  LineElement,
  Filler,
);

function DashboardContent() {
  // Recebendo e classificando os dados
  const state = useStoreInContext(state => state);
  const { faturas: { data, fetch } } = state;
  const { filtro } = useFilter(); // Use o contexto de filtro

  // Garantindo que os dados sejam carregados uma vez
  useEffect(() => {
    if (!data || !data.items) {
      fetch(state, {}); // Fetch without any filters
    }
  }, []);

  const [faturasPagas, setFaturasPagas] = useState([]);
  const [faturasVencidas, setFaturasVencidas] = useState([]);
  const [faturasPendentes, setFaturasPendentes] = useState([]);
  const [faturasEmitidasPorMes, setFaturasEmitidasPorMes] = useState({
    pagas: {},
    vencidas: {},
    pendentes: {},
  });

  useEffect(() => {
    if (data && data.items && Array.isArray(data.items)) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const pagas = [];
      const vencidas = [];
      const pendentes = [];
      const pagasPorMes = {};
      const vencidasPorMes = {};
      const pendentesPorMes = {};

      data.items.forEach(invoice => {
        const { account_id, created_at_iso, due_date, payer_cpf_cnpj, total_cents, status } = invoice;
        let dueDate = new Date(due_date);

        dueDate.setHours(dueDate.getHours() + 3);

        const faturaInfo = { account_id, created_at_iso, due_date: dueDate, payer_cpf_cnpj, total_cents };

        const monthYear = `${dueDate.getMonth() + 1}-${dueDate.getFullYear()}`;

        if (status === 'paid' || status === 'externally_paid') {
          pagas.push(faturaInfo);
          if (!pagasPorMes[monthYear]) pagasPorMes[monthYear] = [];
          pagasPorMes[monthYear].push(faturaInfo);
        } else if ((status === 'pending' || status === 'authorized') && dueDate < today) {
          vencidas.push(faturaInfo);
          if (!vencidasPorMes[monthYear]) vencidasPorMes[monthYear] = [];
          vencidasPorMes[monthYear].push(faturaInfo);
        } else if ((status === 'pending' || status === 'authorized') && dueDate >= today) {
          pendentes.push(faturaInfo);
          if (!pendentesPorMes[monthYear]) pendentesPorMes[monthYear] = [];
          pendentesPorMes[monthYear].push(faturaInfo);
        }
      });

      setFaturasPagas(pagas);
      setFaturasVencidas(vencidas);
      setFaturasPendentes(pendentes);
      setFaturasEmitidasPorMes({
        pagas: pagasPorMes,
        vencidas: vencidasPorMes,
        pendentes: pendentesPorMes,
      });
    }
  }, [data]);

  return (
    <div className="Dashboard">
      <div className="page-header">
        <h2 className="page-title">Dashboard</h2>
      </div>
      <div className="page-content">
        <div className="row">
          <div className='row metric-group'>
            <Faturamento faturasPendentes={faturasPendentes} faturasVencidas={faturasVencidas} faturasPagas={faturasPagas} />
            <div className='row'>
              <Clientes faturasPendentes={faturasPendentes} faturasVencidas={faturasVencidas} faturasPagas={faturasPagas} />
              <Cobrancas faturasPendentes={faturasPendentes} faturasVencidas={faturasVencidas} faturasPagas={faturasPagas} />
            </div>
          </div>
          <div className='row metric-group'>
            <Pizza faturasPendentes={faturasPendentes} faturasVencidas={faturasVencidas} faturasPagas={faturasPagas} />
            <EmitidosRecebidos faturasEmitidasPorMes={faturasEmitidasPorMes} />
          </div>
          <div className='row metric-group standby'>
            <ValGeral className="col-4" title="Valor Recebido geral (R$)" faturasEmitidasPorMes={faturasEmitidasPorMes} />
            <InPeriodo className="col-4" title="Inadimplência no período (%)" faturasEmitidasPorMes={faturasEmitidasPorMes} />
            <InGeral className="col-4" title="Inadimplência geral (%)" faturasEmitidasPorMes={faturasEmitidasPorMes} />
          </div>
        </div>
      </div>
    </div>
  );
}

function Dashboard() {
  return (
    <FilterProvider>
      <DashboardContent />
    </FilterProvider>
  );
}

export default Dashboard;
