import { Link, useLocation, useParams } from "react-router-dom";
import { PlusOutlined } from '@ant-design/icons';
import './style.scss';
    
const FloatButton = ({icon = <PlusOutlined/>, action, className = ''}) => {
  const { id } = useParams();
  const location = useLocation()

  const useCurrentClientId = location.pathname.includes('clientes') && id;

  return (
    <Link onClick={() => useCurrentClientId ? action(id) : action()} className={`float-button ${className}`}>
      {icon} <span className="float-button-text">Nova fatura</span>
    </Link>
  );
};

export default FloatButton;